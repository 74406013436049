// single post dev communnity embed code

.highlight .js-code-highlight {
    overflow: auto;
}

.plaintext {
    background-color:#282828;
    color: #abcbff;
    padding: 0.8rem;
    border-radius: 0.5rem;
    overflow: auto;
}

p {
    code {
      background-color: #282828;
      color: #abcbff;
      padding: 0.2rem;
      border-radius: 0.3rem;
    }
}

  code {
    background-color: #282828;
    color: #abcbff;
    padding: 0.1rem;
    border-radius: 0.1rem;
}

.js-actions-panel {
    display: none;
}
  

.article-body-image-wrapper > img {
    border-radius: 1rem;
    max-width: 100%;
    height: auto;
}
